import React, { createContext, useContext, useState, useEffect } from 'react';

// Create CartContext
const CartContext = createContext();

// Provide CartContext to children
export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(() => {
        // Retrieve the cart from localStorage if it exists
        const storedCart = localStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : [];
    });

    // Add removeItem function
    const removeItem = (indexToRemove) => {
        setCart(cart.filter((_, index) => index !== indexToRemove));
    };

    useEffect(() => {
        // Save the cart to localStorage whenever it changes
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    // CartContext.js
    const addToCart = (item) => {
        const itemIndex = cart.findIndex((cartItem) => cartItem.itemNameEN === item.itemNameEN);
        if (itemIndex >= 0) {
            // If item is already in the cart, do nothing
            console.log('Item already in the cart, no duplicate added.');
        } else {
            // If item is not in the cart, add it with quantity 1
            setCart([...cart, { ...item, quantity: 1 }]);
        }
    };


    // Increase quantity of an item
    const increaseQuantity = (index) => {
        const newCart = [...cart];
        newCart[index].quantity += 1;
        setCart(newCart);
    };

    // Decrease quantity of an item
    const decreaseQuantity = (index) => {
        const newCart = [...cart];
        if (newCart[index].quantity > 1) {
            newCart[index].quantity -= 1;
        } else {
            // If quantity is 1, remove the item from the cart
            removeItem(index);
        }
        setCart(newCart);
    };

    // Clear the cart
    const clearCart = () => {
        setCart([]);
    };

    return (
        <CartContext.Provider value={{ cart, addToCart, removeItem, increaseQuantity, decreaseQuantity, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use CartContext
export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};