import React, { useEffect, useState } from 'react';
import { useCart } from '../context/CartContext'; // Now includes clearCart
import { useTranslation } from 'react-i18next';
import emptyCart_img from '../images/emptyCart_img.png';
import add_icon from '../images/add_icon_black.png';
import minus_icon_black from '../images/minus_icon_black.png';
import delete_icon from '../images/delete_icon.png';
import cookies from "js-cookie";
import back_arrow_EN from '../images/back_arrow_EN.png';
import back_arrow_AR from '../images/back_arrow_AR.png';
import { couponCodes } from './Data';

const Cart = () => {
    const { cart, removeItem, increaseQuantity, decreaseQuantity } = useCart(); // Added clearCart
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next');

    const totalPrice = cart.reduce((total, item) => total + item.price * item.quantity, 0);
    const currency = t('currency', { defaultValue: 'SAR' });

    // Coupon and discount logic
    const [enteredCouponCode, setEnteredCouponCode] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    const [couponError, setCouponError] = useState(''); // For showing error message

    const handleCouponCodeChange = (e) => {
        const code = e.target.value;
        setEnteredCouponCode(code);

        const foundCoupon = couponCodes.find(coupon => coupon.name === code);
        if (foundCoupon) {
            // Check if totalPrice is greater than or equal to the coupon's minimum price
            if (totalPrice < foundCoupon.minmumPrice) {
                setDiscountPercentage(0);
                setCouponError(`${t('Coupon_applicable_above_minimum_price')} ${foundCoupon.minmumPrice} ${currency}`);
                return;
            }
            setDiscountPercentage(foundCoupon.percentage);
            setDisplayedText(currentLanguageCode === 'en' ? foundCoupon.DisplayedTextEN : foundCoupon.DisplayedTextAR);
            setCouponError(''); // Clear any previous error if the coupon is valid
        } else {
            setDiscountPercentage(0);
            setCouponError(t('Invalid_coupon_code')); // Set error message if coupon is invalid
        }
    };

    const discount = (totalPrice * discountPercentage) / 100;
    const totalAfterDiscountAndFee = totalPrice - discount;

    const generateOrderSummary = () => {
        let summary = `${t('orderSummary')}\n`;

        cart.forEach((item) => {
            summary += `* ${currentLanguageCode === 'en' ? item.itemNameEN : item.itemNameAR}*\n`;
            summary += `${t('quantity')}: ${item.quantity} \n`;
            summary += `${t('price')}: ${item.price} ${currency}${item.quantity > 1 ? ` ${currentLanguageCode === 'en' ? 'x' : 'العدد'}${item.quantity} = ${item.price * item.quantity} ${currency}` : ''} \n`;
            summary += '----------\n';
            summary += '\n';
        });

        // Only include discount in WhatsApp message if it's applied
        if (discount > 0) {
            summary += `${t('Coupon_Code')}: ${enteredCouponCode} \n`;
            summary += `${t('Discount')}: ${discount.toFixed(2)} ${currency} \n`;
            summary += `${displayedText} \n`;
            summary += '----------\n \n';
        }

        summary += `* ${t('Total')}: ${totalAfterDiscountAndFee.toFixed(2)} ${currency}*\n \n`;

        summary += `* ${t('Delivery_Title_Note')} ${t('Delivery_Body_Note')}* `;

        return encodeURIComponent(summary);
    };

    const handleConfirmBooking = () => {
        const whatsappLink = `https://wa.me/966570075668?text=${generateOrderSummary()}`;
        window.open(whatsappLink, '_blank'); // Opens WhatsApp in a new tab
    };

    useEffect(() => {
        // Reset coupon logic if total price falls below the minimum for the current coupon
        const foundCoupon = couponCodes.find(coupon => coupon.name === enteredCouponCode);
        if (foundCoupon && totalPrice < foundCoupon.minmumPrice) {
            setDiscountPercentage(0);
            // setEnteredCouponCode('');
            setCouponError(`${t('Coupon_applicable_above_minimum_price')} ${foundCoupon.minmumPrice} ${currency}`);
        }
    }, [discountPercentage, t, totalPrice, enteredCouponCode, currency]); // Trigger every time the total price or entered coupon changes



    return (
        <>
            {/* Mobile View */}
            <div className="p-4 md:hidden">
                <div className='flex justify-end mb-6'>
                    <button>
                        <a href='/'>
                            <img className='h-4' src={currentLanguageCode === 'en' ? back_arrow_EN : back_arrow_AR} alt='Back Arrow' />
                        </a>
                    </button>
                </div>

                {cart.length === 0 ? (
                    <div className='flex flex-col items-center mt-10 gap-10'>
                        <h1 className='text-5xl font-bold text-center'>{t('way_cart_empty')}</h1>
                        <img className='' src={emptyCart_img} alt='Empty Cart' />
                        <button className='w-full px-10 py-3 text-2xl bg-black rounded-2xl text-white'>
                            <a href='/'>
                                {t('lets_fill_it_button')}
                            </a>
                        </button>
                    </div>
                ) : (
                    <>

                        <ul>
                            {cart.map((item, index) => (
                                <li key={index} className="border p-2 my-2 rounded-lg flex justify-between items-center">
                                    <div>
                                        <h2 className="text-lg font-semibold">{currentLanguageCode === 'en' ? item.itemNameEN : item.itemNameAR}</h2>
                                        <p>{t('price', { defaultValue: 'Price' })}: {item.price} {currency}</p>
                                        <p>{t('quantity', { defaultValue: 'Quantity' })}: {item.quantity}</p>
                                    </div>
                                    <div className="flex gap-2">
                                        <button
                                            onClick={() => increaseQuantity(index)}
                                            className="bg-gray-200 text-black px-4 py-2 rounded-lg"
                                        >
                                            <img className='h-3 w-3' src={add_icon} alt='Add Icon' />
                                        </button>
                                        <button
                                            onClick={() => decreaseQuantity(index)}
                                            className="bg-gray-200 text-black px-4 py-2 rounded-lg"
                                        >
                                            <img className='h-4 w-3' src={minus_icon_black} alt='Minus Icon' />
                                        </button>
                                        <button
                                            onClick={() => removeItem(index)}
                                            className="bg-red-500 text-white px-4 py-2 rounded-lg"
                                        >
                                            <img className='h-3 w-3' src={delete_icon} alt='Delete Icon' />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>



                        {/* Coupon code input */}
                        <div className="mt-4">
                            <input
                                type="text"
                                placeholder={t('Enter_coupon_code')}
                                value={enteredCouponCode}
                                onChange={handleCouponCodeChange}
                                className="border p-2 rounded-lg w-full"
                            />
                            {couponError && <p className="text-red-500">{couponError}</p>} {/* Show error message */}
                        </div>

                        {/* Show Subtotal, Delivery Fee, Total Before Discount, Discount, and Total */}
                        <div className="mt-4">

                            {/* Total Before Discount */}
                            {discount > 0 && (
                                <>
                                    <p>{t('Total_Before_Discount')}: <span className="relative inline-block text-gray-500 old-price">{totalPrice} {currency} </span></p>
                                    <p>{t('Discount')}: <span className='text-red-500 font-semibold'> {discount.toFixed(2)} {currency} </span>  |  {displayedText}</p>
                                    <p>---------</p>
                                </>
                            )}

                            {/* Total After Discount */}
                            <p className='font-bold mt-5'>{t('Total')}: {totalAfterDiscountAndFee.toFixed(2)} {currency}</p>
                        </div>

                        {/* Button to Confirm Order */}
                        <div className="flex justify-center mt-6">
                            <button
                                onClick={handleConfirmBooking}
                                className={`bg-green-500 text-white px-4 py-2 rounded-lg w-full`}
                            // disabled={totalPrice < 150} // Disable button if total price is less than 150
                            >
                                {t('Confirm_your_booking')}
                            </button>
                        </div>
                    </>
                )}
            </div>
            {/* Desktop View */}
            <div className="p-4 hidden md:block">
                <div className='flex justify-end mb-6'>
                    <button>
                        <a href='/'>
                            <img className='h-4' src={currentLanguageCode === 'en' ? back_arrow_EN : back_arrow_AR} alt='Back Arrow' />
                        </a>
                    </button>
                </div>

                {cart.length === 0 ? (
                    <div className='flex flex-col items-center mt-10 gap-10'>
                        <h1 className='text-5xl font-bold text-center'>{t('way_cart_empty')}</h1>
                        <img className='' src={emptyCart_img} alt='Empty Cart' />
                        <button className='w-full md:w-1/2 px-10 py-3 text-2xl bg-black rounded-2xl text-white'>
                            <a href='/'>
                                {t('lets_fill_it_button')}
                            </a>
                        </button>
                    </div>
                ) : (
                    <>
                        <div className='grid grid-cols-3 gap-5'>
                            <ul className='col-span-2'>
                                {cart.map((item, index) => (
                                    <li key={index} className="border p-2 my-2 rounded-lg flex justify-between items-center">
                                        <div>
                                            <h2 className="text-lg font-semibold">{currentLanguageCode === 'en' ? item.itemNameEN : item.itemNameAR}</h2>
                                            <p>{t('price', { defaultValue: 'Price' })}: {item.price} {currency}</p>
                                            <p>{t('quantity', { defaultValue: 'Quantity' })}: {item.quantity}</p>
                                        </div>
                                        <div className="flex gap-2">
                                            <button
                                                onClick={() => increaseQuantity(index)}
                                                className="bg-gray-200 text-black px-4 py-2 rounded-lg"
                                            >
                                                <img className='h-3 w-3' src={add_icon} alt='Add Icon' />
                                            </button>
                                            <button
                                                onClick={() => decreaseQuantity(index)}
                                                className="bg-gray-200 text-black px-4 py-2 rounded-lg"
                                            >
                                                <img className='h-4 w-3' src={minus_icon_black} alt='Minus Icon' />
                                            </button>
                                            <button
                                                onClick={() => removeItem(index)}
                                                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                                            >
                                                <img className='h-3 w-3' src={delete_icon} alt='Delete Icon' />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                            <div className='border-[0.1px] p-2 rounded-2xl'>
                                {/* Neighborhood selection */}
                                {/* <div className="mt-4">
                                    <label htmlFor="neighborhood" className="block mb-2">
                                        {t('Select_your_district')}:
                                    </label>
                                    <select
                                        id="neighborhood"
                                        value={selectedNeighborhood}
                                        onChange={(e) => setSelectedNeighborhood(e.target.value)}
                                        className="border p-2 rounded-lg w-full"
                                    >
                                        {sortedNeighborhoods.map((n, index) => (
                                            <option key={index} value={currentLanguageCode === 'en' ? n.nameEN : n.nameAR}>
                                                {currentLanguageCode === 'en' ? n.nameEN : n.nameAR}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}

                                {/* Coupon code input */}
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        placeholder={t('Enter_coupon_code')}
                                        value={enteredCouponCode}
                                        onChange={handleCouponCodeChange}
                                        className="border p-2 rounded-lg w-full"
                                    />
                                    {couponError && <p className="text-red-500">{couponError}</p>} {/* Show error message */}
                                </div>

                                {/* Show Subtotal, Delivery Fee, Total Before Discount, Discount, and Total */}
                                <div className="mt-4">

                                    {/* Total Before Discount */}
                                    {discount > 0 && (
                                        <>
                                            {/* <p>{t('Total_Before_Discount')}: <span className="relative inline-block text-gray-500 old-price">{totalPrice + deliveryFee} {currency} </span></p> */}
                                            <p>{t('Total_Before_Discount')}: <span className="relative inline-block text-gray-500 old-price">{totalPrice} {currency} </span></p>
                                            <p>{t('Discount')}: <span className='text-red-500 font-semibold'> {discount.toFixed(2)} {currency} </span>  |  {displayedText}</p>
                                            <p>---------</p>
                                        </>
                                    )}

                                    {/* Total After Discount */}
                                    <p className='font-bold mt-5'>{t('Total')}: {totalAfterDiscountAndFee.toFixed(2)} {currency}</p>
                                </div>

                                {/* Button to Confirm Order */}
                                <div className="flex justify-center mt-6">
                                    <button
                                        onClick={handleConfirmBooking}
                                        // className={`bg-green-500 text-white px-4 py-2 rounded-lg w-full ${totalPrice < 150 ? 'hidden' : ''}`}
                                        className={`bg-green-500 text-white px-4 py-2 rounded-lg w-full`}
                                    // disabled={totalPrice < 150} // Disable button if total price is less than 150
                                    >
                                        {t('Confirm_your_booking')}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </>
                )}
            </div>
        </>
    );
};

export default Cart;
