import React from 'react'
import { useTranslation } from 'react-i18next';
import cookies from "js-cookie"; // Assuming this was missed for language-specific logic in the commented part

const DeliveryType = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next');

    return (
        <>
            {/* Mobile */}
            <div className='md:hidden'>
                <div className='flex flex-col text-[16px] items-center justify-center'>
                    <h1 className='text-[24px] mb-5 font-bold'>{t('Order_and_Delivery')}</h1>
                    <h1 className='text-[#7E7D7D] font-bold my-2'>{t('Delivery_Title_Note')}
                        <span className='font-normal'>{t('Delivery_Body_Note')}</span></h1>
                    <h1>{t('Delivery_Cost')}
                        <span className='font-bold'>{t('Delivery_Price')}</span></h1>
                </div>
            </div>

            {/* Desktop and Tablet */}
            <div className='hidden md:flex flex-col items-center justify-center'>
                <div className='container mx-auto py-12 px-6'>
                    <h1 className='text-[36px] text-center font-bold mb-8'>{t('Order_and_Delivery')}</h1>

                    <div className='flex flex-col items-center text-center text-[18px]'>
                        <h1 className='text-[#7E7D7D]  font-bold my-4'>
                            {t('Delivery_Title_Note')}
                            <span className='font-normal'>{t('Delivery_Body_Note')}</span>
                        </h1>
                        <h1>{t('Delivery_Cost')}
                            <span className='font-bold'>{t('Delivery_Price')}</span>
                        </h1>
                    </div>

                </div>
            </div>
        </>
    )
}

export default DeliveryType;
